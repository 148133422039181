import React from 'react'
import { Link } from 'gatsby'
import { observer, inject } from "mobx-react"

import SEO from '../components/seo'
import Layout from '../layouts/home'
import Provider from "../store/provider"

const FavCard = ({ vendor, onRemove }) => {
  const { title, location, path, profileImageSrc, categories } = vendor;

  const cats = categories.map(c => c.toUpperCase()).join(' & ')

  return <>
    <div className="featured-place-wrap">
      <img src={profileImageSrc} className="img-fluid" />
      <div className="featured-title-box">
        <h6>{ title }</h6>
        <h6 className="title-cat" title={`In ${cats}`}>In <b>{ cats }</b></h6>
        <p>{ location }</p>
        <div className="row">
          <div className="col">
            <Link to={path} className="btn btn-danger btn-sm">View</Link>
          </div>
          <div className="col">
            <button className="btn btn-sm" onClick={onRemove}>
              <span className="uni-icon" style={{ fontSize: '22px', lineHeight: 0, color: '#333' }}>☒</span> REMOVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </>
}

const FavCards = inject(`store`)(
  observer(({ store }) => {
    if (Object.keys(store.Favorites).length) {
      return Object.keys(store.Favorites).map((key, index) =>
        <div className="col-md-4 col-lg-3 featured-responsive">
          <FavCard
            key={`favs-${index}`}
            vendor={store.Favorites[key]}
            onRemove={(e) => { store.DeleteFav(key) }} />
        </div>
      )
    } else {
      return <div className="col-md-12">
        <p style={{ minHeight: '400px' }}>
          You haven't added any favorites yet. Start browsing and making your shortlist
          by clicking <b>&#10084; Add To Favorite</b> button.
        </p>
      </div>
    }
  })
)

const FavCardContainer = () => <Provider><FavCards /></Provider>

export default function FavoritesPage() {

  return <Layout>
    <SEO
      title="Favorites Manager"
      keywords={['wedding', 'nepal', 'vendors', 'favorites', 'compare']}
      description="Shortlist and compare your favorite vendor for your events, ceremonies and weddings."
      ogTitle="Favorites Manager - CeremonyNepal"
    />
    <section className="reserve-block light-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h5>&#10084; My Favorites Shorlisted</h5>
          </div>
        </div>
      </div>
    </section>
    <section className="reserve-block">
      <div className="container">
        <div className="row">
          <FavCardContainer />
        </div>
      </div>
    </section>
  </Layout>
}

